// Handle limit typing decimal 2,3 digit
const getNumberAfterDecimalPoint = (num) => {
  let numberString = num.toString();
  let splitNumber = numberString.split(".");
  let result = splitNumber[1] ? splitNumber[1] : "0";
  return result.length;
};

// Handle limit typing 20 character
const maxLengthNumber = (num) => {
  let numberString = num.toString();
  let splitNumber = numberString.split(".");
  let result = splitNumber[0] ? splitNumber[0] : "0";
  return result.length;
};
// limit characters
const limitCharacters = (num) => {
  return;
};
